export function formatCurrency(value:number, currency:string = 'USD'): string{
    return new Intl.NumberFormat(navigator.language, { style: 'currency', currency:  currency, currencyDisplay: 'code', maximumFractionDigits:2, minimumFractionDigits:0}).format(value);
}
  
export function formatDate(date:string| Date, options:Intl.DateTimeFormatOptions = {}): string{
    options = Object.assign({year: 'numeric', month: '2-digit', day: '2-digit'}, options);
    date = new Date(date);
    return date.toLocaleDateString(navigator.language, options);
}

export function formatNumber(value:number, options = {}): string {
    return new Intl.NumberFormat(navigator.language, options).format(value);
}